import React, { Component } from 'react';

class Photo extends Component {
    render() {

        if(this.props.data){
            var photos = this.props.data.projects.map(function(photo, index){
                var projectImage = 'images/photo/'+photo.image;
                return <div key={`photo-${index}`} className="columns photos-item">
                    <div className="item-wrap">
                        <a href={photo.url} title={photo.title}>
                            <img alt={photo.title} src={projectImage} />
                            <div className="overlay">
                                <div className="photos-item-meta">
                                    <h5>{photo.title}</h5>
                                    <p>{photo.category}</p>
                                </div>
                            </div>
                            <div className="link-icon"><i className="fa fa-link"></i></div>
                        </a>
                    </div>
                </div>
            })
        }

        return (
            <section id="photos">

                <div className="row">

                    <div className="twelve columns collapsed">

                        <h1>My Instagram feed</h1>

                        <div id="photos-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
                            {photos}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Photo;
