import React, { Component } from 'react';
import ReactGA from 'react-ga4';

class Footer extends Component {
  handleClick = (e, platform, url) => {
    e.preventDefault(); // Prevent default link behavior
    ReactGA.event({
      category: 'Social Links',
      action: 'Click',
      label: platform,
    });

    // Add a slight delay to ensure the GA event is sent before navigating
    setTimeout(() => {
      window.location.href = url;
    }, 300);
  };

  render() {

    if(this.props.data){
      var name = this.props.data.name;
      var version = this.props.data.version;
      var networks = this.props.data.social.map((network) => {
        return (
        <li key={network.name}>
          <a
          href={network.url} 
          onClick={(e) => this.handleClick(e, network.name, network.url)}
          >
            <i className={network.className}></i>
          </a>
          </li>
      );
      })
    }

    return (
      <footer>

     <div className="row">
        <div className="twelve columns">
           <ul className="social-links">
              {networks}
           </ul>

           <ul className="copyright">
              <li>&copy; Copyright 2024 {name}.</li>
               <li>Version {version}.</li>
           </ul>

        </div>
        <div id="go-top"><a className="smoothscroll" title="Back to Top" href="#home"><i className="icon-up-open"></i></a></div>
     </div>
  </footer>
    );
  }
}

export default Footer;
